import { createSelector } from 'reselect'

import {
  selectTicketSearchOperatorFullValueMap,
  selectCurrentCommittedTicketSearchQueryCurrentPart,
} from 'selectors/search/base'
import { getAgentUsername } from 'util/agents'
import { emptyArr, isEmpty, uniq } from 'util/arrays'
import { filter } from 'util/objects'
import wrapSearchValueInQuotesIfNeeded from 'util/search/wrapSearchValueInQuotesIfNeeded'
import { escapeRegExp } from 'util/strings'
import { selectCurrentTicketReplyDraftAllContactIds } from 'ducks/drafts2/selectors'
import { hasMailboxesWithSameName } from 'util/mailboxes'
import {
  selectCurrentTagsById,
  selectTagsByMailboxId,
  selectTagsBySearch,
} from 'ducks/tags/selectors'
import { selectSuggestionList } from 'ducks/searches/selectors/selectSuggestionList'
import { selectChannelCustomFields } from 'ducks/crm/channels/selectors/selectChannelCustomFields'
import { DROPDOWN, MULTI_SELECT } from 'ducks/crm/customFields/types'
import { customFieldKeyToSearchKey } from 'ducks/searches/utils/query'
import { selectCurrentConversationMessages } from 'ducks/tickets/selectors/selectCurrentConversationMessages'
import {
  selectContacts,
  selectContactsById,
} from 'ducks/crm/contacts/selectors/base'
import { selectCurrentConversationEvents } from 'ducks/tickets/selectors/selectCurrentConversationEvents'
import { selectSuggestedContacts } from './users'
import { selectKnownMailboxes } from '../ducks/mailboxes/selectors/selectKnownMailboxes'

const selectModifierSuggestions = createSelector(
  selectSuggestionList,
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  (suggestionList, currentPart) => {
    const { operator, part } = currentPart || {}
    return sameArrayIfEmpty(
      suggestionList.filter(value => {
        if (!currentPart.part) return true
        const { suggestion } = value
        if (suggestion === part || suggestion === `${operator}:`) return false
        return suggestion.match(new RegExp(`(^|:)${escapeRegExp(part)}`, 'i'))
      })
    )
  }
)

const selectMySuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  currentPart => {
    if (!currentPart) return emptyArr
    const { operator, part, value } = currentPart
    const operatorMatch = operator === 'keywords' || operator === 'my'
    const suggestions = [
      { suggestion: 'unread', searchQuery: 'my:unread' },
      { suggestion: 'open', searchQuery: 'my:open' },
      { suggestion: 'drafts', searchQuery: 'my:drafts' },
      { suggestion: 'snoozed', searchQuery: 'my:snoozed' },
      { suggestion: 'closed', searchQuery: 'my:closed' },
      { suggestion: 'starred', searchQuery: 'my:starred' },
      { suggestion: 'deleted', searchQuery: 'my:deleted' },
    ]
    const isComplete = !!suggestions.find(x => x.searchQuery === part)
    if (isComplete) return suggestions
    return sameArrayIfEmpty(
      suggestions.filter(
        ({ searchQuery }) =>
          (operatorMatch &&
            searchQuery.match(new RegExp(`:${escapeRegExp(value)}`, 'i'))) ||
          part === 'my:'
      )
    )
  }
)

const selectIsSuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  currentPart => {
    if (!currentPart) return emptyArr
    const { operator, part, value } = currentPart

    const operatorMatch = operator === 'keywords' || operator === 'is'
    const suggestions = [
      { suggestion: 'unread', searchQuery: 'is:unread' },
      { suggestion: 'open', searchQuery: 'is:open' },
      { suggestion: 'unassigned', searchQuery: 'is:unassigned' },
      { suggestion: 'assigned', searchQuery: 'is:assigned' },
      { suggestion: 'snoozed', searchQuery: 'is:snoozed' },
      { suggestion: 'closed', searchQuery: 'is:closed' },
      { suggestion: 'starred', searchQuery: 'is:starred' },
      { suggestion: 'deleted', searchQuery: 'is:deleted' },
      { suggestion: 'spam', searchQuery: 'is:spam' },
      { suggestion: 'rated', searchQuery: 'is:rated' },
    ]
    const isComplete = !!suggestions.find(x => x.searchQuery === part)
    if (isComplete) return suggestions
    return sameArrayIfEmpty(
      suggestions.filter(
        ({ searchQuery }) =>
          (operatorMatch &&
            searchQuery.match(new RegExp(`:${escapeRegExp(value)}`, 'i'))) ||
          part === 'is:'
      )
    )
  }
)

const makeSelectAgentSuggestions = inputOperator => {
  return createSelector(
    selectCurrentCommittedTicketSearchQueryCurrentPart,
    selectTicketSearchOperatorFullValueMap,
    buildAgentSuggestions(inputOperator)
  )
}

function buildAgentSuggestions(inputOperator) {
  return (currentPart, valueMap) => {
    if (!currentPart) return emptyArr

    const { operator, part, value } = currentPart || {}
    const { assignee: agents } = valueMap
    const { me } = agents

    if (!me) return emptyArr

    const results = Object.keys(agents)
      .map(key => {
        const agent = agents[key]
        if (key === 'me' || agent.username === me.username) return null
        const username = getAgentUsername(agent)
        return {
          avatar_url: agent.avatar_url,
          suggestion: username || agent.name,
          name: agent.name,
          hint: agent.name,
          username,
          email: agent.email,
          searchQuery: `${inputOperator}:${wrapSearchValueInQuotesIfNeeded(
            key
          )}`,
        }
      })
      .filter(agent => !!(agent && agent.username))
      .sort((a, b) => {
        if (a.username < b.username) return -1
        if (a.username > b.username) return 1
        return 0
      })

    if (me) {
      const username = getAgentUsername(me)
      results.unshift({
        avatar_url: me.avatar_url,
        suggestion: username,
        name: me.name,
        hint: 'You',
        username,
        email: me.email,
        searchQuery: `${inputOperator}:me`,
        keywords: 'me you',
      })
    }

    if (inputOperator === 'assignee') {
      results.unshift({
        nullValue: true,
        suggestion: 'Unassigned',
        hint: `not assigned to ${app.t('agent_with_article')}`,
        searchQuery: `${inputOperator}:unassigned`,
      })
    }

    const operatorMatch = operator === 'keywords' || operator === inputOperator
    const isComplete = !!results.find(x => x.searchQuery === part)

    if (isComplete) return results

    return sameArrayIfEmpty(
      results.filter(result => {
        if (!result) return false
        const { suggestion, name, username, keywords } = result
        const matchAgainst = [null, suggestion, name, username, keywords].join(
          ' '
        )
        return (
          (operatorMatch &&
            matchAgainst.match(
              new RegExp(`(^|:|\\s)${escapeRegExp(value)}`, 'i')
            )) ||
          part === `${inputOperator}:`
        )
      })
    )
  }
}

const selectAssigneeSuggestions = makeSelectAgentSuggestions('assignee')
const selectMentionsSuggestions = makeSelectAgentSuggestions('mentions')
const selectDraftSuggestions = makeSelectAgentSuggestions('drafts')

const selectGroupSuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  selectTicketSearchOperatorFullValueMap,
  (currentPart, valueMap) => {
    if (!currentPart) return emptyArr

    const { operator, part, value } = currentPart
    const { assigned_group: groups } = valueMap
    const results = Object.values(groups).map(group => ({
      suggestion: group.name,
      searchQuery: `group:${wrapSearchValueInQuotesIfNeeded(group.name)}`,
    }))
    results.unshift({
      nullValue: true,
      suggestion: 'Unassigned',
      hint: `not assigned to ${app.t('group')}`,
      searchQuery: `group:unassigned`,
    })

    const operatorMatch = operator === 'keywords' || operator === 'group'
    const isComplete = !!results.find(x => x.searchQuery === part)

    if (isComplete) return results

    return sameArrayIfEmpty(
      results.filter(result => {
        if (!result) return false
        return (
          (operatorMatch &&
            result.searchQuery.match(
              new RegExp(`(:|\\s)${escapeRegExp(value)}`, 'i')
            )) ||
          part === 'group:'
        )
      })
    )
  }
)

function mapLabel(label) {
  return {
    suggestion: label.name,
    searchQuery: `tag:${wrapSearchValueInQuotesIfNeeded(label.name)}`,
  }
}

const selectLabelSuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  selectTagsBySearch,
  selectTagsByMailboxId,
  selectCurrentTagsById,
  (currentPart, tagsBySearch, labelsByMailbox, allLabelsById) => {
    if (!currentPart) return null
    const { operator, part, value } = currentPart || {}
    const loading = [{ isLoading: true }]

    const topLabels = labelsByMailbox.null
    if (part === 'tag:') {
      if (topLabels?.length) return topLabels.map(mapLabel)
      if (Object.values(allLabelsById).length) {
        return Object.values(allLabelsById)
          .slice(0, 5)
          .map(mapLabel)
      }
      return loading
    }
    const results = tagsBySearch[value]
    const operatorMatch = operator === 'keywords' || operator === 'tag'
    if (results && operatorMatch) {
      const size = operator === 'tag' ? 20 : 5
      return sameArrayIfEmpty(results.slice(0, size).map(mapLabel))
    }
    if (operatorMatch) return loading
    return null
  }
)

const selectRatingSuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  currentPart => {
    if (!currentPart) return emptyArr
    const { operator, part, value } = currentPart

    const operatorMatch = operator === 'rating'
    const suggestions = [
      { suggestion: 'awesome', searchQuery: 'rating:awesome' },
      { suggestion: 'ok', searchQuery: 'rating:ok' },
      { suggestion: 'bad', searchQuery: 'rating:bad' },
    ]
    const isComplete = !!suggestions.find(x => x.searchQuery === part)
    if (isComplete) return suggestions
    return sameArrayIfEmpty(
      suggestions.filter(
        ({ searchQuery }) =>
          (operatorMatch &&
            searchQuery.match(new RegExp(`:${escapeRegExp(value)}`, 'i'))) ||
          part === 'rating:'
      )
    )
  }
)

const selectCustomFieldOperatorsBySearchKey = createSelector(
  selectChannelCustomFields,
  customFields => {
    const results = customFields.reduce((acc, cf) => {
      if ([DROPDOWN, MULTI_SELECT].includes(cf.type)) {
        const searchKey = customFieldKeyToSearchKey(cf.key)
        // eslint-disable-next-line no-param-reassign
        acc[searchKey] = cf.options
      }
      return acc
    }, {})

    return results
  }
)

const selectCustomFieldSuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  selectCustomFieldOperatorsBySearchKey,
  (currentPart, customFieldOperators) => {
    if (!currentPart) return emptyArr
    const { operator, part, value } = currentPart
    const values = customFieldOperators[operator]
    if (!values) return emptyArr

    const suggestions = values.map(o => ({
      suggestion: o.label,
      searchQuery: `${operator}:"${o.value}"`,
    }))
    const isComplete = !!suggestions.find(x => x.searchQuery === part)
    if (isComplete) return suggestions
    return sameArrayIfEmpty(
      suggestions.filter(
        ({ searchQuery }) =>
          searchQuery.match(new RegExp(`:${escapeRegExp(value)}`, 'i')) ||
          part === `${operator}:`
      )
    )
  }
)

const selectFolderSuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  selectTicketSearchOperatorFullValueMap,
  (currentPart, valueMap) => {
    if (!currentPart) return emptyArr

    const { operator, part, value } = currentPart || {}
    const { folder: folders } = valueMap
    const results = Object.values(folders).map(folder => ({
      suggestion: folder.name,
      name: folder.name,
      searchQuery: `folder:${wrapSearchValueInQuotesIfNeeded(folder.name)}`,
    }))
    const operatorMatch = operator === 'keywords' || operator === 'folder'
    const isComplete = !!results.find(x => x.searchQuery === part)

    if (isComplete) return results

    return sameArrayIfEmpty(
      results.filter(result => {
        if (!result) return false
        const { suggestion, name, hint } = result
        const matchAgainst = [null, suggestion, name, hint].join(' ')
        return (
          (operatorMatch &&
            matchAgainst.match(
              new RegExp(`(:|\\s)${escapeRegExp(value)}`, 'i')
            )) ||
          part === 'folder:'
        )
      })
    )
  }
)

export const selectMailboxSuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  selectKnownMailboxes,
  (currentPart, mailboxes) => {
    if (!currentPart) return emptyArr

    const { operator, part, value } = currentPart || {}
    const hasSameNamedMailboxes = hasMailboxesWithSameName(mailboxes)
    const results = mailboxes.map(mailbox => ({
      suggestion: mailbox.name,
      name: mailbox.name,
      // Show email along with mailbox name if there're multiple mailboxes with the same name
      email: hasSameNamedMailboxes ? mailbox.email : undefined,
      searchQuery: `inbox:${wrapSearchValueInQuotesIfNeeded(
        hasSameNamedMailboxes ? mailbox.email : mailbox.name
      )}`,
      id: mailbox.id,
    }))

    const operatorMatch = operator === 'inbox'
    const isComplete = !!results.find(x => x.searchQuery === part)

    if (isComplete) return results

    const regex = new RegExp(`:${escapeRegExp(value)}`, 'i')

    return sameArrayIfEmpty(
      results.filter(
        ({ name }) =>
          (operatorMatch && `inbox:${name}`.match(regex)) || part === 'inbox:'
      )
    )
  }
)

const selectPossibleReplyRecipientsFromDraftForCurrentTicket = createSelector(
  selectCurrentConversationEvents,
  selectCurrentConversationMessages,
  selectCurrentTicketReplyDraftAllContactIds,
  selectContactsById,
  (events, messages, draftContactIds, contactsById) => {
    // NOTE (jscheel): Use object to prevent duplicates by id. DO NOT RETURN
    // THIS OBJECT! YOU SHOULD BE RETURNING Object.values(recipients).
    const recipients = {}

    draftContactIds.forEach(cid => {
      const messageContact = contactsById[cid]
      if (messageContact) recipients[messageContact.id] = messageContact
    })

    messages.forEach(message => {
      ;['cc', 'bcc'].forEach(type => {
        ;(message[type] || []).forEach(clink => {
          const messageContact = contactsById[clink.id]
          if (messageContact) recipients[messageContact.id] = messageContact
        })
      })
      if (message.author?.schema === 'Contact') {
        const messageContact = contactsById[message.author.id]
        if (messageContact) recipients[messageContact.id] = messageContact
      }
    })
    events.forEach(event => {
      // Skip emailmessages as that is handled above
      if (event.change?.schema === 'EmailMessage') return

      const actor = event.actor
      if (!actor) return
      if (actor.schema !== 'Contact') return
      const actorContact = contactsById[actor.id]
      if (actorContact) recipients[actorContact.id] = actorContact
    })

    return Object.values(recipients)
  }
)

const selectCurrentSuggestedContacts = createSelector(
  selectPossibleReplyRecipientsFromDraftForCurrentTicket,
  selectSuggestedContacts,
  (possibleRecipients, suggestedContacts) => {
    return uniq([...suggestedContacts, ...possibleRecipients])
  }
)

export const selectFromSuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  selectContacts,
  selectCurrentSuggestedContacts,
  (currentPart, allContacts, suggestedContacts) => {
    if (!currentPart) return null
    const { operator, part } = currentPart || {}
    const loading = [{ isLoading: true }]
    const contacts = part === 'from:' ? allContacts : suggestedContacts
    if (Object.values(contacts).length === 0) return null

    const contactsWithEmail = Object.values(contacts).filter(
      customer => customer.email
    )
    const results = Object.values(contactsWithEmail).map(contact => ({
      suggestion: contact.name || contact.email,
      name: contact.name,
      avatar_url: contact.avatarUrl,
      email: contact.email,
      searchQuery: `from:${wrapSearchValueInQuotesIfNeeded(contact.email)}`,
      id: contact.email,
    }))
    const operatorMatch = operator === 'from' || operator === 'keywords'
    if (results && operatorMatch) {
      const size = operator === 'from' ? 20 : 5
      return sameArrayIfEmpty(results.slice(0, size))
    }
    if (operatorMatch) return loading
    return null
  }
)

export const selectTicketSearchSuggestions = createSelector(
  selectCurrentCommittedTicketSearchQueryCurrentPart,
  selectModifierSuggestions,
  selectMySuggestions,
  selectIsSuggestions,
  selectAssigneeSuggestions,
  selectGroupSuggestions,
  selectDraftSuggestions,
  selectLabelSuggestions,
  selectMentionsSuggestions,
  selectRatingSuggestions,
  selectFolderSuggestions,
  selectMailboxSuggestions,
  selectFromSuggestions,
  selectCustomFieldSuggestions,
  (
    currentPart,
    modifier,
    my,
    is,
    assignee,
    group,
    drafts,
    label,
    mentions,
    rating,
    folder,
    mailbox,
    from,
    customField
  ) => {
    return filter(value => !!value, {
      [currentPart.part ? 'modifiers' : 'narrow']: modifier,
      my,
      from,
      is,
      assignee,
      group,
      label,
      drafts,
      mentions,
      rating,
      folder,
      mailbox,
      customField,
    })
  }
)

export const selectIsTicketSearchSuggestions = createSelector(
  selectTicketSearchSuggestions,
  suggestions => {
    const allSuggestionsCount = Object.values(suggestions).reduce(
      (output, value) => {
        return output + value.length
      },
      0
    )
    return allSuggestionsCount !== 0
  }
)

// perf - dont return a brand new array object if you dont need to.
// Return the frozen one instead.
function sameArrayIfEmpty(array) {
  if (isEmpty(array)) return emptyArr
  return array
}
